import React, { useState, useEffect } from "react";
import { m } from "framer-motion";
import useAnnouncementData from "@staticQueries/AnnouncementQuery";
import { Text, Icon, Container } from "@atoms";
import { AppLink } from "@base";

const Announcement = () => {
  const { enabled, heading, copy, button } = useAnnouncementData();
  const [show, setShowing] = useState(enabled);

  useEffect(() => {
    setTimeout(() => {
      const seen =
        window.localStorage.getItem("announcement") === heading + copy;
      if (seen) {
        setShowing(false);
      }
    }, 0);
  }, []);

  const variants = {
    show: {
      height: "auto",
      pointerEvents: "auto",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    hide: {
      height: 0,
      pointerEvents: "none",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <m.div
      initial="hide"
      variants={variants}
      animate={show ? "show" : "hide"}
      className="overflow-hidden bg-pink"
    >
      <Container padding className="relative py-5 md:py-2">
        <div className="relative flex flex-col items-start justify-between gap-3 text-white md:flex-row md:items-center md:gap-5">
          <div className="flex max-w-xl flex-col gap-2">
            <Text className="font-heading text-lg font-bold leading-tight">
              {heading}
            </Text>
            <Text className="text-xs font-medium leading-normal">{copy}</Text>
          </div>
          <div className="flex items-center gap-5">
            {button?.url && (
              <AppLink
                className="flex-shrink-0 font-heading text-sm font-bold underline decoration-white decoration-2 underline-offset-[.25rem] duration-500 hover:decoration-black"
                to={button.url}
              >
                {button.text}
              </AppLink>
            )}
            <button
              type="button"
              className="flex items-center justify-center"
              onClick={() => {
                window.localStorage.setItem("announcement", heading + copy);
                setShowing(false);
              }}
            >
              <span className="sr-only">close</span>
              <Icon
                name="close"
                className="h-4 w-4 text-white duration-500 hover:text-black"
              />
            </button>
          </div>
        </div>
      </Container>
    </m.div>
  );
};

export default Announcement;
